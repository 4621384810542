.ant-menu-item-active {
  color: rgba(0, 100, 192, 1) !important;
}
.ant-menu-item-active:active {
  color: rgba(0, 100, 192, 1) !important;
}
.ant-menu-item-selected {
  color: rgba(0, 100, 192, 1) !important;

  background-color: transparent !important;
}

.ant-menu-item-selected::after {
  border-right: none !important;
}
.ant-menu-root.ant-menu-inline {
  border-right: rgba(0, 100, 192, 1) 2px solid !important;
}
.ant-menu-item .ant-menu-item-icon,
.ant-menu-submenu-title .ant-menu-item-icon,
.ant-menu-item .anticon,
.ant-menu-submenu-title .anticon {
  font-size: 18px;
}
